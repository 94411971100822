.DateRangePicker {
    position: fixed;
    z-index: 10;
    position: fixed;
    background: white;
}

.rdrDay {
    width: 80px !important;
}

.rdrMonth {
   width: 650px !important;
}