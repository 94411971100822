body {
  margin: 20px;
  padding: 20px;
  background-color: #8b9795;
}

nav {
  background-color: #8b9795;
}

.bodyLogIn {
  margin: -20px;
}

.profileMain {
  border-radius: 10px;
  background-color: #F0F0F0;

  height: 100vh;
}

.profilColum1 {

  background-color: #6D88A6;
  color: #F0F0F0;
  border-radius: inherit;
}

.profilColum1Content {
  padding: 18px;
}

.profilColum2 {


  color: #6D88A6;
  border-radius: inherit;
}

.profilColum2Content {
  padding: 30px;
  color: #6D88A6;
  display: flex;
}


button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
  margin: 5px;

}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

span {
  cursor: pointer;

}

.centerMenu {
  display: flex;
  justify-content: center;
  align-items: center;

}

.buttonMenu Button {
  height: 250px;
  width: 250px;
}

.buttonMenu {

  display: inherit;
}

g[aria-labelledby="id-228-title"] {
  display: none;
}